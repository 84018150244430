import React, { useState, useEffect } from 'react';
import styles from './css/style.module.css';

const Popup = () => {

const joinGroup = () => {
      window.open("https://chat.whatsapp.com/CFb2nZ2ET4ZCzACf2JIDfX", "_blank")    
    }

    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {

    const hasClosedPopup = sessionStorage.getItem('hasClosedPopup');

    if (!hasClosedPopup) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem('hasClosedPopup', 'true'); 
  };

    return (
      isVisible&&
      <div className={styles.popupContainer}>
        <div className={styles.popupContent}>
          <button className={styles.closeButton} onClick={handleClose}>×</button>
          <div className={styles.text}>
          <h2>Welcome to Antaragni'24!</h2>
          <p>Reach us here and be a part of this spectacular cultural festival.</p>
          <div className={styles.linkContainer}>
              <span className={styles.linkText}>Join Us on WhatsApp :</span>
              <span className={styles.JoinBtn} onClick={joinGroup}>Channel Link</span>
        </div>
        </div>
        </div>
      </div>
    );
};

export default Popup;
